/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './CategoryTree.scss';
import {classes as optionClasses, cssStates} from './CategoryOption.st.css';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {TextButton} from 'wix-ui-tpa';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../stylesParamsContext';

export const CategoryTreeDataHook = {
  Title: 'category-tree-title',
  Content: 'category-tree-content',
  Container: 'category-tree-container',
  CategoryOption: {
    at: (i: number) => `category-option-${i}`,
  },
};

export const CategoryTree: React.FunctionComponent<IGalleryGlobalProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(({t, globals}: IGalleryGlobalProps & IProvidedTranslationProps) => {
    const {categories, currentCategory} = globals;

    const styles = useStyles();
    const stylesParams = useStylesParams();

    const shouldShowCategoryTitle = styles.get(stylesParams.gallery_showCategoriesTitle);

    /* istanbul ignore next: will be refactored when bi event will be updated*/
    const onCategoryClicked = (e, destinationCategoryId: string, destinationLink: string) => {
      const {handleCategoryClick} = globals;
      e.preventDefault();
      handleCategoryClick({destinationCategoryId, destinationLink});
    };

    const categoryTitle = () => {
      return shouldShowCategoryTitle ? (
        <h2 data-hook={CategoryTreeDataHook.Title} className={s.title}>
          {t('categoryTreeTitle')}
        </h2>
      ) : null;
    };

    return (
      <div className={s.container} data-hook={CategoryTreeDataHook.Container}>
        {categoryTitle()}
        <div data-hook={CategoryTreeDataHook.Content} className={s.content}>
          <ul className={s.options}>
            {categories.map((category, i) => (
              <li key={category.id}>
                <TextButton
                  data-hook={CategoryTreeDataHook.CategoryOption.at(i)}
                  as="a"
                  className={`${optionClasses.option} ${cssStates({
                    activeCategory: currentCategory.id === category.id,
                  })}`}
                  priority={'secondary' as any}
                  href={category.categoryUrl}
                  onClick={
                    /* istanbul ignore next: will be refactored when bi event will be updated*/ (event) =>
                      onCategoryClicked(event, category.id, category.categoryUrl)
                  }>
                  {category.name}
                </TextButton>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  })
);
